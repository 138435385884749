import React from "react";
import "./slider.scss";

const Slider = () => {
  return (
    <div className="slider">
      <h3>Slider Images</h3>
      <div className="imageGrid">
        <div><img src="" alt="" />image 1</div>
        <div><img src="" alt="" />image 2</div>
        <div><img src="" alt="" />image 3</div>
      </div>
    </div>
  );
};

export default Slider;
