import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./productdetails.scss";
const url = process.env.REACT_APP_API_URL
const dUrl = process.env.REACT_APP_DASHBOARD_URL

const ProductDetails = ({ inputs, titles }) => {
  const navigate = useNavigate()

  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState("");
  const [trade_name, setTrade_name] = useState("");
  const [generic_name, setGeneric_name] = useState("");
  const [therapeutic_class, setTherapeutic_class] = useState("");
  const [description, setDescription] = useState("");
  const [indication, setIndication] = useState("");
  const [dosage_and_administration, setDosage_and_administration] =
  useState("");
  const [supply, setSupply] = useState("");
  const [keyword, setKeyword] = useState("");
  const [crntProduct, setCrntProduct] = useState();
  const [user, setUser] = useState("")
  const location = useLocation();

  const id = location.pathname.split("/")[3];

  const getProducts = async () => {

    const res = await axios.get(`${url}/products/${id}`);
    console.log(" direct from res.data ", res.data.image);
    setCrntProduct(res.data);
    setImage(res.data.image);
    setPdf(res.data.pdf);
    setTrade_name(res.data.trade_name);
    setGeneric_name(res.data.generic_name);
    setTherapeutic_class(res.data.therapeutic_class);
    setDescription(res.data.description);
    setIndication(res.data.indication);
    setDosage_and_administration(res.data.dosage_and_administration);
    setSupply(res.data.supply);
    setKeyword(res.data.keyword);
  };
  useEffect(() => {
    getProducts();
    const token = localStorage.getItem("token")
    if(!token) {
      navigate('/login')
    } else {
      setUser(token)
    }
  }, []);

  const postData = async () => {
    const newProduct = {
      trade_name,
      generic_name,
      therapeutic_class,
      description,
      indication,
      dosage_and_administration,
      supply,
      keyword,
    };

    if (image) {
      const data = new FormData();
      const imgname = Date.now() + image.name;
      data.append("name", imgname);
      data.append("image", image);
      newProduct.image = imgname;
  
      try {
        await axios.post(`${url}/products/upimg`, data,
        { 
          'Content-Type': 'application/json',
          headers: {"Authorization" : `Bearer ${user}`} 
        }, 
        );
      } catch (err) {
        console.log("np try prd", err);
      }
    }
    if (pdf) {
      const data = new FormData();
      const pdfname = Date.now() + pdf.name;
      data.append("name", pdfname);
      data.append("pdf", pdf);
      newProduct.pdf = pdfname;
      try {
        await axios.post(`${url}/products/upimg`, data,
        { 
          'Content-Type': 'application/json',
          headers: {"Authorization" : `Bearer ${user}`} 
        } );
      } catch (err) {
        console.log("np try prd", err);
      }
    }
    try {
      console.log("newProduct", newProduct);
      await axios.put(`${url}/products/${id}`, newProduct,
      { 
        'Content-Type': 'application/json',
        headers: {"Authorization" : `Bearer ${user}`} 
      }, {
        trade_name,
        generic_name,
        therapeutic_class,
        description,
        indication,
        dosage_and_administration,
        supply,
        keyword,
      },
      
      );
      window.location.replace(`${dUrl}/products/`);
    } catch (err) {
      console.log("np try", err);
    }
  };
  const handleSubmit = (event) => {
    alert("Product Uploaded Successfully");
    event.preventDefault();
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{titles} Edit Product</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <div>
              <span style={{ fontSize: "11px", marginTop: "12px" }}>
                Image Preview
              </span>
            </div>
            <img
              src={
                image
                  ? `${url}/products/${crntProduct.image}`
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
            <div style={{ fontSize: "11px", marginTop: "12px" }}>
              <span>PDF Preview</span>
            </div>
            <div>
            </div>
            <a href={`${url}/products/${pdf}`} download>Download PDF</a>
            <img
              src={
                pdf
                  ? "https://icon-library.com/images/2018/6902206_virat-kohli-png-circle-transparent-png.png"
                  : "https://icon-library.com/images/2018/6006857_dot-circle-gira-228203-png-download.png"
              }
              alt=""
            />
            <div>
              {" "}
              <span
                style={{
                  color: "gray",
                  fontSize: "10px",
                  fontFamily: "cursive",
                }}
              >
                {pdf.name}
              </span>{" "}
            </div>
          </div>
          <div className="right">
            <form onSubmit={handleSubmit} enctype="multipart/form-data">
              <div className="formInput">
                <label htmlFor="">Product Image*</label>
                <label className="imageLabel" htmlFor="imgfile">
                  Choose Image:{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="imgfile"
                  name="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  style={{ display: "none" }}
                />

                <label htmlFor="">Product/Trade Name*</label>
                <input
                  type="text"
                  value={trade_name}
                  placeholder="Product Name"
                  onChange={(e) => setTrade_name(e.target.value)}
                />
                <label>Generic Name*</label>
                <input
                  type="text"
                  placeholder="Generic Name"
                  value={generic_name}
                  onChange={(e) => setGeneric_name(e.target.value)}
                />
                <label>Therapeutic Class</label>
                <input
                  type="text"
                  placeholder="Therapeutic Class"
                  value={therapeutic_class}
                  onChange={(e) => setTherapeutic_class(e.target.value)}
                />
                <label>Description</label>
                <textarea
                  className="des"
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <label>Indication</label>
                <input
                  type="text"
                  placeholder="Indication"
                  value={indication}
                  onChange={(e) => setIndication(e.target.value)}
                />
                <label>Dosage And Administration</label>
                <input
                  type="text"
                  placeholder="Dosage_and_administration"
                  value={dosage_and_administration}
                  onChange={(e) => setDosage_and_administration(e.target.value)}
                />
                <label>Supply</label>
                <input
                  type="text"
                  placeholder="Supply"
                  value={supply}
                  onChange={(e) => setSupply(e.target.value)}
                />
                <label>Keyword (Optional) </label>
                <input
                  type="text"
                  placeholder="keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <label>Product PDF</label>
                <label className="imageLabel" htmlFor="pdffile">
                  Choose pdf: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="pdffile"
                  name="pdf"
                  onChange={(e) => setPdf(e.target.files[0])}
                  style={{ display: "none" }}
                />

                <input
                  className="btn"
                  onClick={postData}
                  type="submit"
                  value="Save"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
