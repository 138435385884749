import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import './homepage.scss'
import Slider from '../../components/slider/slider'

const EditHomePage = () => {
  return (
    <div className="list">
    <Sidebar/>
    <div className="listContainer">
      <Navbar/>
      <br></br>
       <Slider/>
    </div>
  </div>
  )
}

export default EditHomePage
