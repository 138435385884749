import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./newHubAddress.scss";

const url = process.env.REACT_APP_API_URL
const dUrl = process.env.REACT_APP_DASHBOARD_URL

const NewHubAddress = ({ inputs, title }) => {
  const navigate = useNavigate()
  const [district, setDistrict] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [user, setUser] = useState("")

  useEffect(()=> {
    const token = localStorage.getItem("token")
    if (!token) {
      navigate('/login')
    } else {
      setUser(token)
    }
  }, [])

  console.log("hub new token: -", user)
  const handleSubmit = (event) => {
    event.preventDefault()
  }
  const postAddress = async () => {
    console.log("opst addres clicked")
    const newAddress = {
      address,
      district,
      phone      
    }
    console.log("prepared for post", newAddress)
    try {
      const respp = await axios.post(`${url}/hubaddress/`, newAddress,
      { 
        'Content-Type': 'application/json',
        headers: {"Authorization" : `Bearer ${user}`} 
      }
      )
      console.log("responds form server", respp)
      //window.location.replace(`${dUrl}/hub-address`)
    } catch (err) {
      console.log("error is--", err);
    }
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit}>
              <label>District</label>
              <input
                type="text"
                placeholder="Enter District"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              />
              <label>Phone</label>
              <input
                type="text"
                placeholder="Enter Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label> Address</label>
              <input
                type="text"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <input
                className="btn"
                onClick={postAddress}
                type="submit"
                value="Save"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHubAddress;
