import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { addressColumns } from "../../datatablesource";
import './addresstable.scss'

const url = process.env.REACT_APP_API_URL
const dUrl = process.env.REACT_APP_DASHBOARD_URL

// const url = "http://localhost:4000/hubaddress";

const AddressTable = () => {
  let navigate = useNavigate();
  const [address, setAddress] = useState([]);
  const [user, setUser] = useState('')

  const addresses = async () => {
    try {
      const res = await axios.get(`${url}/hubaddress`);
      setAddress(res.data.data.address);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    addresses();
    const token = localStorage.getItem("token")
    setUser(token)
  }, []);

  const handleDelete = (id) => {
    axios.delete(`${url}/hubaddress/${id}`, 
    {
      'Content-Type': 'application/json',
      headers: {"Authorization" : `Bearer ${user}`} 
    }
    ).then(() => {
      navigate("hub-address");
    });
    alert("Successfully Deleted");
    window.location.replace(`${dUrl}/hub-address/`);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/hub-address/edit/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  if (!address) return "no data";

  return (
    <div className="AddressTable">
      <div className="AddressTableTitle">
        Products List
        <Link to="/hub-address/new" className="link">
          Add New Product
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        getRowId={(row) => row._id}
        rows={address}
        columns={addressColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default AddressTable;
