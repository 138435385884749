import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DarkModeContext } from "./context/darkModeContext";
import { productInputs } from "./formSource";
import AboutPage from "./pages/aboutPage/aboutPage";
import Home from "./pages/home/Home";
import EditHomePage from "./pages/homePage/HomePage";
import HubAddress from "./pages/hubAddress/HubAddress";
import Login from './pages/login/Login';
import NewHubAddress from "./pages/new/newHubAddress";
import NewProduct from "./pages/new/NewProduct";
import ProductsPage from "./pages/products/Products";
import HubAddressUpdate from "./pages/update/hubAddressUpdate";
import ProductDetils from "./pages/update/UpdateProducts";
import "./style/dark.scss";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>

          <Route path="/">
            <Route index element={<Home />} />
            
            <Route path="login" element={<Login />} />
            {/* Product route-------------------*/}
            <Route path="/products">
              <Route index element={<ProductsPage />} />
              <Route path="edit/:id" element={<ProductDetils />} />
              <Route
                path="new-product"
                element={<NewProduct inputs={productInputs} title="Add New Product" />}
              />
               
            </Route> 
            {/* Address Route------------------*/}
            <Route path="/hub-address">
              <Route index element={<HubAddress />} /> 
              <Route
                path="new"
                element={<NewHubAddress inputs={productInputs} title="Add Distribution Network" />}
              />
               <Route path="edit/:id" element={<HubAddressUpdate/>} />  
            </Route>   
            <Route path="/edit-home">
              <Route index element={<EditHomePage />} />  
            </Route>     
            <Route path="/edit-contact">
              <Route index element={<EditHomePage />} />  
            </Route>
            <Route path="/edit-about">
              <Route index element={<AboutPage />} />  
            </Route>
            <Route path="/edit-header">
              <Route index element={<EditHomePage />} />  
            </Route>  
            <Route path="/edit-footer">
              <Route index element={<EditHomePage />} />  
            </Route> 
            
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
