import "./producttable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, {useState, useEffect} from 'react'
import axios from 'axios'
const url = process.env.REACT_APP_API_URL
const dUrl = process.env.REACT_APP_DASHBOARD_URL

const ProductsTable = () => {
const [data, setData] = useState([])

const fetchData = async () => {
  const res = await axios.get(`${url}/products`)
  res.data.length = 5
    setData(res.data)
}

useEffect(() => {
  fetchData()
},[])

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Product Id</TableCell>
            <TableCell className="tableCell">Date</TableCell>
            <TableCell className="tableCell">Image</TableCell>
            <TableCell className="tableCell">Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row._id}</TableCell>
              <TableCell className="tableCell">{row.date}</TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={`${url}/products/${row.image}`} alt="" className="image" />
                 
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.trade_name}</TableCell>
              {/* <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
