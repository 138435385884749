import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

const AboutPage = () => {
 return (
    <div className="list">
    <Sidebar/>
    <div className="listContainer">
      <Navbar/>
      <br></br>
       <h1>About us page elements</h1>
    </div>
  </div>
  )
}

export default AboutPage