import jwt from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "../../components/chart/Chart";
import Featured from "../../components/featured/Featured";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Table from "../../components/table/ProductTable";
import Widget from "../../components/widget/Widget";
import "./home.scss";

const Home = () => {
  const [user, setUser] = useState('')
  const navigate = useNavigate("");

  useEffect(() => {    
    const token = localStorage.getItem("token");
    console.log("check token", token)
    if (!token) {
      navigate("/login");
    }
    else{
      const user = jwt(token)
      if (user) {
        // localStorage.removeItem("token");
        navigate("/");
      }
    }
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="Products" />
          <Widget type="Hubs" />
          <Widget type="categories" />
          <Widget type="Admin User" />
        </div>
        <div className="charts">
          <Featured />
          <Chart title="Last 6 Months (Visitors)" aspect={2 / 1} />
        </div>
        <div className="listContainer">
          <div className="listTitle">Latest Products</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Home;
