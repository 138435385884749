import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { productColumns } from "../../datatablesource";
import "./producttable.scss";
const url = process.env.REACT_APP_API_URL
const dUrl = process.env.REACT_APP_DASHBOARD_URL
// const url = "http://backend.leonbd.com/products";

const Datatable = () => {
  let navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState('')

  const postings = async () => {
    try {
      const res = await axios.get(`${url}/products`);
      setPosts(res.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) {
      navigate('/login')
    } else {
      setUser(token)
    }
    postings();
  }, [])

  const [data, setData] = useState(posts);

  const handleDelete = (id) => {
    axios.delete(`${url}/products/${id}`, {
      'ContentType': 'application/json',
      headers: {"Authorization": `Bearer ${user}`}
    }).then(() => {
      navigate("/products");
    });
    alert("Successfully Deleted");
    window.location.replace(`${dUrl}/products/`);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/products/edit/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  if (!posts) return "no data";

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Products List
        <Link to="/products/new-product" className="link">
          Add New Product
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        getRowId={(row) => row._id}
        
        rows={posts}
        columns={productColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
