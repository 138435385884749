import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import HubIcon from "@mui/icons-material/Hub";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PagesIcon from "@mui/icons-material/Pages";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import "./sidebar.scss";


const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const navigate = useNavigate()
   const logout = () => {
    localStorage.removeItem("token");
    // localStorage.clear('token')
    navigate('/login')
  }

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Leon Admin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <li>
            <DashboardIcon className="icon" />
            <Link to="/" style={{ textDecoration: "none" }}>
              <span>Dashboard</span>
            </Link>
          </li>
          {/* <span></span>
          <li>
            <CategoryIcon className="icon" />
            <span>Catagory</span>
          </li> */}
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <LocalPharmacyIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <Link to="/hub-address" style={{ textDecoration: "none" }}>
            <li>
              <HubIcon className="icon" />
              <span>Hubs</span>
            </li>
          </Link>
          <p className="title">Pages and Others</p>
          <Link to="/edit-header" style={{ textDecoration: "none" }}  >
          <li>
            <PagesIcon className="icon" />
            <span>Header</span>
          </li>
          </Link>
          <Link to="/edit-footer" style={{ textDecoration: "none" }}  >
          <li>
            <PagesIcon className="icon" />
            <span>Footer</span>
          </li>
          </Link>
          
          <Link to="/edit-home" style={{ textDecoration: "none" }} >
          <li>
            <PagesIcon className="icon" />
            <span>Home Page</span>
          </li>
          </Link>
         
          <Link to="/edit-contact" style={{ textDecoration: "none" }} >
          <li>
            <PagesIcon className="icon" />
            <span>Contact Page</span>
          </li>
          </Link>
         
         <Link  to="/edit-about" style={{ textDecoration: "none" }} >
         <li>
            <PagesIcon className="icon" />
            <span>AboutUs Page</span>
          </li>
         </Link>
        
          <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li>
            <ExitToAppIcon className="icon" />
            <button className="logoutButton" onClick={logout} >
            <span>Logout</span>
            </button>
            
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
