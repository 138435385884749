// const url = "http://backend.leonbd.com";
const url = process.env.REACT_APP_API_URL
// let count = 1
export const addressColumns = [
    {
      field: 'sl',
      headerName: "Sl",
      width: 100,
      renderCell: (params) => {
        let count = 1
      return (
        
        <div className="cellWithImg">
              {count ++}
        </div>
      ); 
    }
  },
  {
    field: "_id",
    hide: true,
    headerName: "ID",
    width: 100,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row._id}
        </div>
      ); 
    }
  }, 
  {
    field: "district",
    headerName: "District",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
        {params.row.district}
        </div>
      );
    },
  },

  {
    field: "phone",
    headerName: "Phone Nubmers",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
         {params.row.phone}
        </div>
      );
    },
  },
  {
    field: "address",
    headerName: "Address",
    width: 230,
    renderCell: (params) => {
      return (        
        <div className="cellWithImg">
          {params.row.address}
        </div>
      );
    },
  },
 
];



export const productColumns = [
  { 
    field: "id", 
    headerName: "SL", 
    width: 70, 
    filterable: false,
  },
  {
    field: "date",
    headerName: "Date",
    width: 85,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          abc {params.row.date} 
        </div>
      ); 
    }
  },
  {
    field: "_id",
    hide: true,
    headerName: "ID",
    width: 100,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row._id}
        </div>
      ); 
    }
  },
  {
    field: "image",
    headerName: "Image ",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={`${url}/products/${params.row.image}`} alt="avatar" />
        </div>
      );
    },
  },
  
  {
    field: "trade_name",
    headerName: "Product Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row.trade_name}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

