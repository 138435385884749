import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./hubAddress.scss";
import AddressTable from "../../components/addressTable/AddressTable"
import { useNavigate } from "react-router-dom";

const HubAddress = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem("token")
    if(!token){
      navigate('/login')
    } 
  }, [])
  
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <AddressTable/>
      </div>
    </div>
  );
};

export default HubAddress;
