import "./hubAddressUpdate.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios"
import { getCardHeaderUtilityClass } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
const url = process.env.REACT_APP_API_URL
const dUrl = process.env.REACT_APP_DASHBOARD_URL
// const url = "http://backend.leonbd.com";

const HubAddressUpdate = ({ inputs, title }) => {

  const navigate = useNavigate()

  const [district, setDistrict] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [user, setUser] = useState("")

  const location = useLocation()
  const id = location.pathname.split("/")[3]
  const getAddress = async () => {
   const res = await axios.get(`${url}/hubaddress/${id}`)
   setDistrict(res.data.district)
   setPhone(res.data.phone)
   setAddress(res.data.address)
  }
    useEffect(() => {
        getAddress()
        const token = localStorage.getItem("token")
        if (!token) {
          navigate('/login')
        } else {
          setUser(token)
        }
    }, [])

const postAddress = async()=> {
  const newAddress = {
    district, 
    phone, 
    address
  }
  try {
    await axios.put(`${url}/hubaddress/${id}`, newAddress, 
    { 
      'Content-Type': 'application/json',
      headers: {"Authorization" : `Bearer ${user}`} 
    }
    )
    window.location.replace(`${dUrl}/hub-address`)
  }catch(err) {
    console.log(err)
  }
}

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form  >
            <label>District</label>
                <input
                  type="text"
                  placeholder="Enter District"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              <label>Phone</label>
                <input
                  type="text"
                  placeholder="Enter Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                 <label> Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  className="btn"
                  onClick={postAddress}
                  type="submit"
                  value="Save"
                />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubAddressUpdate;
